<template>
  <div>
    <b-card v-if="!checkAgent" title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
    </b-card>
    <center>
      <b-card>
        <div v-if="checkAgent" style="margin-top: 0vh">
          <!-- statics -->
          <statistic-card-vertical
            style="min-width: 150px; max-width: 200px; font-size: 20px; border-radius: 40%"
            icon="DollarSignIcon"
            color="info"
            :statistic="total"
            statistic-title="إجمالي العهده"
          />
        </div>
      </b-card>
    </center>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    StatisticCardVertical
  },
  data() {
    return {
      checkAgent: false,
      total: 0
    }
  },
  created() {
    if (JSON.parse(localStorage.getItem('userInfo')).role.includes('User')) {
      this.GetAgent()

      this.checkAgent = true
    }
  },
  methods: {
    async GetAgent() {
      try {
        // let agentId = this.$store.getters.ExtractAcessToken.userId;
        const response = await this.$store.dispatch('GetAgentWithoutId')
        if ((response.status = 200)) {
          this.total = response.data.custody
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    }
  }
}
</script>
